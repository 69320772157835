let awsRegion = 'us-east-1';

export default {
 api_urls: {
   reporting: 'https://zxm5nqtaoi.execute-api.us-east-1.amazonaws.com/api',
   geojson: 'https://foc3j5615k.execute-api.us-east-1.amazonaws.com/api',
   overlays: 'https://bl0eqfuhf0.execute-api.us-east-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 191,
 crm_base_url: 'https://riotinto.crm.forwoodsafety.com',
 forwood_id_url: 'https://id.riotinto.forwoodsafety.com?redirect_uri=https://geoeditor.riotinto.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.riotinto.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-1_gbRwzUwDX',
   userPoolWebClientId: '5669riomc2n2e77gtb38h13ken',
   cookieStorage: {
     domain: '.riotinto.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
